<template>
  <div>
    <div class="review-contain">
      <h4>审核详情--无用？</h4>
      <el-form ref="reviewForm" :model="reviewForm" :rules="reviewRules" v-if="showAuditForm">
        <el-form-item label="审核状态" prop="auditStatus" class="audit-form-item">
          <el-radio-group v-model="reviewForm.auditStatus">
            <el-radio :label="1" style="margin-bottom: 0">通过</el-radio>
            <el-radio :label="2" style="margin-bottom: 0">不通过</el-radio>
            <el-radio :label="3" style="margin-bottom: 0">返回修<span title="audit.vue">改</span></el-radio>
          </el-radio-group>
          <el-alert v-if="reviewForm.auditStatus == '2'" title="请慎用“不通过”操作。不通过后，该“记录/项目”将被终止。填报人无法修改。若想让填报人修改此数据，请选择“返回修改”，填报人可以有一次修改机会。" style="line-height:20px;" type="warning" show-icon></el-alert>
        </el-form-item>
        <el-form-item label="审核意见" prop="reviewRemark" style="line-height:0px;">
          <el-input type="textarea" v-model="reviewForm.reviewRemark" placeholder="请输入审核意见"></el-input>
        </el-form-item>
        <el-form-item label="审核签字" prop="reviewSign" v-if="formDetail.auditAuthSign">
          <div class="signature-box" :class="[fieldModel?'':'empty']" @click="showSignBox" :style="{backgroundImage:'url('+fieldModel+')'}"></div>
        </el-form-item>
        <el-form-item style="text-align:center">
          <el-button type="primary" size="medium" @click="onSubmitMultipleReviewClick" v-if="isMultipleSelectionFill">提交</el-button>
          <el-button type="primary" size="medium" @click="onSubmitReviewClick" v-else>提交</el-button>
          <el-button @click="onCancelReviewClick">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="signature-dialog window-style" :class="[direction]" v-if="isShowSignBox">
      <!-- <div class="title">手写签名</div> -->
      <VueSignaturePad ref="signaturePad" @on-save="saveSign" @ondirection="setDirection" :options="options" style="box-sizing:content-box;" />
      <!-- <div class="signature-tool">
        <div style="display:inline-block">
          <div class="signature-tool-item" v-if="!disabled" @click="clearSignatureMobile">清空</div>
          <div class="signature-tool-item" v-if="!disabled" @click="undoSignatureMobile">撤销</div>
        </div>
        <div style="display:inline-block">
          <el-button size="mini" type="primary" @click="saveSign" v-if="!disabled">确定</el-button>
          <el-button size="mini" @click="closeSign">关闭</el-button>
        </div>
      </div> -->
    </div>

    <template v-if="isH5">
      <div style="width:0px;height:0px;overflow:hidden">
        <iframe ref="messageProxy" :src="postMessageProxy"></iframe>
      </div>
    </template>
  </div>
</template>
<script>

import axios from 'axios'
import Cookies from 'js-cookie'
import { aliUploader } from '@/utils/oss'
import { formDetail, review, auditFillUpdate, batchReviewFill } from '@/api/system/form'
import AuditForm from '@/views/system/form/components/audit/audit-form.vue'

import { getUserInfo } from '@/api/system/user'
import { getSessionStorageObj, setSessionStorageObj } from '@/utils/db'
export default {
  components:{
    AuditForm
  },
  data () {
    return {

      fieldModel: "",
      disable: false,
      fillId: "",
      fillIdList: [],
      formId: "",
      status: "",
      version: "",
      auditType: "audit",
      reviewRules: {},
      showAuditForm: false,


      // signatureWidth: 'calc(100vw - 104px)',
      // signatureHeight: 'calc(100vh - 24px)',
      signatureWidth: 'calc(100vw - 24px)',
      signatureHeight: 'calc(100vw * 0.66 - 24px * 0.66)',

      formDetail: {
        auditAuthSign: false,
        agencyId: 0,
        fillId: 0,
        formId: 0,
        status: 0,

      },
      reviewForm: {
        auditStatus: -1,
        reviewRemark: "",
        reviewSign: ''
      },

      reviewRules: {
        auditStatus: [
          {
            required: true,
            message: '审核状态不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },

      rules: [],
      options: {
        penColor: "#000",
        clear: true,
        undo: true,
        confirm: true,
        title: true,
        // backgroundColor: 'rgba(255,255,255,1)',
      },
      direction: '',
      aliOSSUpload: null,
      isMultipleSelectionFill: false,
      fileName: "",
      source: "",    //wechat_audit 微信小程序审核 h5_fill 填报时扫码签字  h5_audit PC审核时扫码签字
      isShowSignBox: false,
      isMiniProgram: false,
      isH5: false,
      postMessageProxy: '',
      disabled: false
    }
  },
  created () {
    this.formId = this.$route.params.formId

    if (this.$route.query.fillId) {
      this.fillId = this.$route.query.fillId
      this.version = this.$route.query.version
      this.isMultipleSelectionFill = false;
      this.auditType = this.$route.query.auditType

    } else {
      this.fillIdList = JSON.parse(decodeURIComponent(this.$route.query.fillIdList))
      this.isMultipleSelectionFill = true;
    }

        this.reviewForm.auditStatus= Number(this.$route.query.auditStatus);
        this.reviewForm.reviewRemark = this.$route.query.auditRemark;
        this.reviewForm.reviewSign=this.$route.query.reviewSign;
        this.fieldModel= this.$route.query.reviewSign;

    const token = this.$route.params.token
    Cookies.set('User-Token', token)

    let userInfo = getSessionStorageObj('users')
    if (!userInfo) {
      getUserInfo().then(res => {
        setSessionStorageObj('users', res.data)
        userInfo = res.data
        this.getFormDetail(this.formId)
      })
    } else {
      this.getFormDetail(this.formId)
    }

  },
  async mounted () {

    this.isH5 = this.$route.query.h5 == "true"
    this.isMiniProgram = window.navigator.userAgent.toLocaleLowerCase().indexOf('miniprogram') > -1;
    console.log(this.isMiniProgram ? '微信小程序中运行' : '非微信小程序运行');
    if (this.isH5) {
      this.postMessageProxy = decodeURIComponent(this.$route.query.proxy)
    }

    if(this.$route.query.auditStatus){
      this.reviewForm.auditStatus = Number( this.$route.query.auditStatus);
      this.reviewForm.reviewRemark = this.$route.query.auditRemark;
      // this.reviewForm.reviewSign 
      this.fieldModel= decodeURIComponent(this.$route.query.reviewSign);
    }


    this.showSign()
  },
  // watch: {
  //   value: function (val) {
  //     // this.fieldModel = val
  //   }
  // },
  methods: {
    showSign () {
      let $this = this;
      let signaturePad = $this.$refs.signaturePad
      let canvasImageData;

      //先显示出来
      this.$nextTick(async () => {

        this.aliOSSUpload = aliUploader(false, { //OK
          formId: this.formId || 'formId',
          agencyId: this.agencyId,
        })

        if (this.fieldModel) {
          canvasImageData = (await axios.get($this.fieldModel + ".txt")).data;
        }
        const rotateData = canvasImageData;
        // this.rotateBase64Img(canvasImageData, 90, rotateData => {

        //判断是新表单、查看、修改 三种模式的哪一种
        //禁用的一定是查看模式
        if ($this.disabled) {
          //显示保存的数据
          signaturePad.lockSignaturePad();
          //如果是移动版，需要旋转90度再显示
          signaturePad.fromDataURL(rotateData, { xOffset: 0, yOffset: 0 })
          // }
        } else {
          //否则可能是修改或者新增
          //判断fieldModel是否为空，为空就是新增或者修改的原纪录无信息，判断是否使用历史信息
          if (!$this.fieldModel && signaturePad) {
            let image = localStorage.getItem("v-form-signature");
            if (image) {
              signaturePad.fromDataURL(image, { xOffset: 0, yOffset: 0 })
            }
          } else if(signaturePad) {          
              signaturePad.fromDataURL(rotateData, { xOffset: 0, yOffset: 0 })            
          }
        }

        // })

      });
    },
    async saveSign () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      new Promise((resolve) => {
        const rotateData = data;

        this.cropSignatureCanvas(rotateData, 30).then(async (imageData) => {
          this.fileName = (new Date()).valueOf() + "audit-sign.png"
          try {
            let file = this.dataUrlToFile(imageData, this.fileName)
            let originFileData = this.dataUrlToBase64File(rotateData, this.fileName + ".txt")

            //裁剪前的Base64
            this.aliOSSUpload.config = {
              uploadPath: `auditSign/${this.agencyId}/${this.formId}/${this.fillId}/${this.status}`,
              fixedName: true
            }



            const uploadImage = () => {
              return new Promise(uploadResolve => {
                this.aliOSSUpload.config.uploadSuccessHandler = (file, fileList) => {
                  this.fieldModel = file.url;
                  this.reviewForm.reviewSign = this.fieldModel;

                  uploadResolve();
                }
                this.aliOSSUpload.elFileUpload({ file: file })
              })
            }
            const uploadTxt = () => {
              return new Promise(uploadResolve => {
                this.aliOSSUpload.config.uploadSuccessHandler = (file, fileList) => {
                  if (!isEmpty) {
                    if (file.url.endsWith(".txt")) {
                      //保存到localStorage
                      localStorage.setItem("v-form-signature", data);
                    }
                  }
                  uploadResolve();
                }
                this.aliOSSUpload.elFileUpload({ file: originFileData })
              })
            }

            Promise.resolve().then(uploadImage).then(uploadTxt).then(() => {
              //通知微信签好了返回
              console.log("post message", this.fieldModel)
              this.isShowSignBox = false;
              resolve();
            }).catch(() => { console.log(e) })




          } catch (e) {
            this.$message.warning("保存签字出错");
            console.error("保存签字出错")
            console.error(e)
          }
        });


        // })

      }).then(() => {
        // wx.miniProgram.navigateBack()
        this.isShowSignBox = false;
      })
    },
    closeSign () {
      this.isShowSignBox = false;
      // wx.miniProgram.navigateBack()
    },
    undoSignatureMobile () {
      this.$refs.signaturePad.undoSignature()
    },
    clearSignatureMobile () {
      this.$refs.signaturePad.clearSignature()

      localStorage.removeItem("v-form-signature");
    },
    // base64图片旋转方法
    rotateBase64Img (src, edg, callback) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw '旋转角度必须是90的倍数!';
      }
      (edg < 0) && (edg = (edg % 360) + 360)
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = {
        sx: 0,
        sy: 0,
        ex: 0,
        ey: 0
      }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous"

      image.onload = () => {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }


        ctx.translate(size, size);
        ctx.rotate(edg * Math.PI / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        callback(canvas.toDataURL())
      };

      image.src = src;

    },
    setReviewRules () {
      // 根据配置判断，审核意见是否必填？
      if (this.formDetail.auditAuthReviewRemark) {
        this.reviewRules.reviewRemark = [
          {
            required: true,
            message: "请填写审核意见",
            trigger: "blur",
          },
        ];
      }
      // 根据配置判断是否手写签名必填。
      if (this.formDetail.auditAuthSign && this.formDetail.auditAuthReviewSign) {
        this.reviewRules.reviewSign = [
          {
            required: true,
            message: "请填写签名",
            trigger: "blur",
          },
        ];
      }

    },
    //裁剪签名空白区，并返回图片
    async cropSignatureCanvas (url, padding = 0) {
      return new Promise((resolve, reject) => {
        // create canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // create image
        const image = new Image();
        image.onload = draw;
        image.src = url;
        image.crossOrigin = "Anonymous";

        function draw () {
          canvas.width = image.width;
          canvas.height = image.height;

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const { data, width, height } = imageData;

          // 裁剪需要的起点和终点,初始值为画布左上和右下点互换设置成极限值。
          let startX = width,
            startY = height,
            endX = 0,
            endY = 0;

          /*
          col为列，row为行，两层循环构造每一个网格，
          便利所有网格的像素，如果有色彩则设置裁剪的起点和终点
          */
          for (let col = 0; col < width; col++) {
            for (let row = 0; row < height; row++) {
              // 网格索引
              const pxStartIndex = (row * width + col) * 4;

              // 网格的实际像素RGBA
              const pxData = {
                r: data[pxStartIndex],
                g: data[pxStartIndex + 1],
                b: data[pxStartIndex + 2],
                a: data[pxStartIndex + 3]
              };

              // 存在色彩：不透明
              const colorExist = pxData.a !== 0;

              /*
              如果当前像素点有色彩
              startX坐标取当前col和startX的最小值
              endX坐标取当前col和endX的最大值
              startY坐标取当前row和startY的最小值
              endY坐标取当前row和endY的最大值
              */
              if (colorExist) {
                startX = Math.min(col, startX);
                endX = Math.max(col, startX);
                startY = Math.min(row, startY);
                endY = Math.max(row, endY);
              }
            }
          }

          // 右下坐标需要扩展1px,才能完整的截取到图像
          endX += 1;
          endY += 1;

          // 加上padding
          startX -= padding;
          startY -= padding;
          endX += padding;
          endY += padding;

          // 根据计算的起点终点进行裁剪
          const cropCanvas = document.createElement("canvas");
          const cropCtx = cropCanvas.getContext("2d");
          cropCanvas.width = endX - startX;
          cropCanvas.height = endY - startY;
          cropCtx.drawImage(
            image,
            startX,
            startY,
            cropCanvas.width,
            cropCanvas.height,
            0,
            0,
            cropCanvas.width,
            cropCanvas.height
          );

          //设置白色背景
          const bgImage = cropCtx.getImageData(0, 0, cropCanvas.width, cropCanvas.height);
          for (let i = 0; i < imageData.data.length; i += 4) {
            // 当该像素是透明的，则设置成白色
            if (bgImage.data[i + 3] === 0) {
              bgImage.data[i] = 255;
              bgImage.data[i + 1] = 255;
              bgImage.data[i + 2] = 255;
              bgImage.data[i + 3] = 255;
            }
          }
          cropCtx.putImageData(bgImage, 0, 0);

          // rosolve裁剪后的图像字符串
          resolve(cropCanvas.toDataURL());
        }

      })
    },
    dataUrlToFile (dataurl, fileName) {
      try {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let blob = new Blob([u8arr], { type: mime });

        blob.lastModifiedDate = new Date();
        blob.name = fileName;
        return blob;
      } catch (e) {
        return dataurl;
      }
    },
    dataUrlToBase64File (dataurl, fileName) {
      let mime = 'text/plain'
      let blob = new Blob([dataurl], { type: mime });

      blob.lastModifiedDate = new Date();
      blob.name = fileName;
      return blob;
    },
    getFormDetail (formId) {
      // 获取表单其他配置
      formDetail(formId).then((response, request) => {
        this.formDetail = response.data
        this.setReviewRules();
        this.showAuditForm = true;
      })
    },
    showSignBox () {
      this.isShowSignBox = true;  
      
      this.$nextTick(()=>{
        this.showSign()
      });
    },
    // 新版---审核弹框 确认点击
    onSubmitReviewClick () {
      this.$refs['reviewForm'].validate((valid) => {
        if (valid) {
          const param = {
            auditStatus: parseInt(this.reviewForm.auditStatus)
          }
          if (!this.isBlank(this.reviewForm.reviewRemark)) {
            param.reviewRemark = this.reviewForm.reviewRemark
          }
          if (!this.isBlank(this.formDetail.auditAuthSign)) {  //保存审核签名
            param.reviewSign = this.fieldModel
          }
          param.fillId = this.fillId
          param.formId = this.formId
          param.version = this.version



          const reviewApi = this.auditType == 'audit' ? review : auditFillUpdate;
          reviewApi(param).then((response) => {
            if (response.code == 200) {
              this.msgSuccess('审核成功')

              const redirectData = {
                data: {
                  redirect: `auditConfirm`
                }
              }

              let url =`/pages/form/fill/fillv2/postMessageProxy?action=audit&formId=${this.formId}`
              if (this.isMiniProgram) {
                // wx.miniProgram.postMessage(redirectData)
                // wx.miniProgram.navigateBack()
                wx.miniProgram.redirectTo({  url });
              } else {
                try {
                  uni.postMessage(redirectData)
                  uni.navigateBack()
                } catch {
                  // this.$refs.messageProxy.contentWindow.postMessage(JSON.stringify(redirectData), "*")
                   window.location.replace(url);
                }
              }

            } else {
              this.$message(response.msg)
            }
          })
        }
      })
    },

    onSubmitMultipleReviewClick () {
      this.$refs['reviewForm'].validate((valid) => {
        if (valid) {

          const param = {
            auditStatus: parseInt(this.reviewForm.auditStatus),
            formId: this.formId,
            fillList: this.fillIdList
          }
          if (!this.isBlank(this.reviewForm.reviewRemark)) {
            param.reviewRemark = this.reviewForm.reviewRemark
          }
          if (!this.isBlank(this.formDetail.auditAuthSign)) {  //保存审核签名
            param.reviewSign = this.fieldModel
          }

          param.type = 0

          batchReviewFill(param).then((response) => {
            if (response.code == 200) {
              this.msgSuccess('已审核')

              const redirectData = {
                data: {
                  redirect: `auditConfirm`
                }
              }
              if (this.isMiniProgram) {
                wx.miniProgram.postMessage(redirectData)
                wx.miniProgram.navigateBack()
              } else {
                try {
                  uni.postMessage(redirectData)
                  uni.navigateBack()
                } catch {
                  this.$refs.messageProxy.contentWindow.postMessage(JSON.stringify(redirectData), "*")
                }
              }

            } else {
              this.$message(response.msg)
            }
          })
        }
      })
    },
    onCancelReviewClick () {

      if (this.isMiniProgram) {
        wx.miniProgram.navigateBack()
      } else {
        try {
          uni.navigateBack()
        } catch {
          const redirectData = {
            data: {
              redirect: `back`
            }
          }
          this.$refs.messageProxy.contentWindow.postMessage(JSON.stringify(redirectData), "*")
          window.history.back();
        }
      }
    },
    setDirection(direction){
      this.direction = direction
    }
  }
}
</script>
<style lang="scss" scoped>
.signature-box {
  width: 100%;
  height: 70px;
  border: solid 1px #e5e5e5;
  margin-top: 35px;

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.empty {
    text-align: center;
    line-height: 70px;
    color: #a5a5a5;
    &::before {
      content: '请点击此处进行签名';
    }
  }
}

.signature-dialog {
  position: fixed;
  // top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px;
  height: auto;
  box-sizing: border-box;

  background-color: #f5f5f5;
  z-index: 777;

  &.window-style {
    top: auto;
    padding-right: 10px;
    padding-left: 10px;
  }
  &.horizontal{
    top:0px;
  }
}

.signature-box {
  width: 100%;
  height: 70px;
  border: solid 1px #e5e5e5;

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.empty {
    text-align: center;
    line-height: 70px;
    color: #a5a5a5;
    &::before {
      content: '请点击此处进行签名';
    }
  }
}

.review-contain {
  padding: 10px;
  box-sizing: border-box;
}
.audit-form-item {
  line-height: 40px !important;
}
</style>
