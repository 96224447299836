var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "review-contain" },
        [
          _c("h4", [_vm._v("审核详情--无用？")]),
          _vm.showAuditForm
            ? _c(
                "el-form",
                {
                  ref: "reviewForm",
                  attrs: { model: _vm.reviewForm, rules: _vm.reviewRules },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "audit-form-item",
                      attrs: { label: "审核状态", prop: "auditStatus" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.reviewForm.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.reviewForm, "auditStatus", $$v)
                            },
                            expression: "reviewForm.auditStatus",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: 1 },
                            },
                            [_vm._v("通过")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: 2 },
                            },
                            [_vm._v("不通过")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: 3 },
                            },
                            [
                              _vm._v("返回修"),
                              _c("span", { attrs: { title: "audit.vue" } }, [
                                _vm._v("改"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.reviewForm.auditStatus == "2"
                        ? _c("el-alert", {
                            staticStyle: { "line-height": "20px" },
                            attrs: {
                              title:
                                "请慎用“不通过”操作。不通过后，该“记录/项目”将被终止。填报人无法修改。若想让填报人修改此数据，请选择“返回修改”，填报人可以有一次修改机会。",
                              type: "warning",
                              "show-icon": "",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "line-height": "0px" },
                      attrs: { label: "审核意见", prop: "reviewRemark" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入审核意见",
                        },
                        model: {
                          value: _vm.reviewForm.reviewRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.reviewForm, "reviewRemark", $$v)
                          },
                          expression: "reviewForm.reviewRemark",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formDetail.auditAuthSign
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "审核签字", prop: "reviewSign" } },
                        [
                          _c("div", {
                            staticClass: "signature-box",
                            class: [_vm.fieldModel ? "" : "empty"],
                            style: {
                              backgroundImage: "url(" + _vm.fieldModel + ")",
                            },
                            on: { click: _vm.showSignBox },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _vm.isMultipleSelectionFill
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "medium" },
                              on: { click: _vm.onSubmitMultipleReviewClick },
                            },
                            [_vm._v("提交")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "medium" },
                              on: { click: _vm.onSubmitReviewClick },
                            },
                            [_vm._v("提交")]
                          ),
                      _c(
                        "el-button",
                        { on: { click: _vm.onCancelReviewClick } },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isShowSignBox
        ? _c(
            "div",
            {
              staticClass: "signature-dialog window-style",
              class: [_vm.direction],
            },
            [
              _c("VueSignaturePad", {
                ref: "signaturePad",
                staticStyle: { "box-sizing": "content-box" },
                attrs: { options: _vm.options },
                on: { "on-save": _vm.saveSign, ondirection: _vm.setDirection },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isH5
        ? [
            _c(
              "div",
              {
                staticStyle: {
                  width: "0px",
                  height: "0px",
                  overflow: "hidden",
                },
              },
              [
                _c("iframe", {
                  ref: "messageProxy",
                  attrs: { src: _vm.postMessageProxy },
                }),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }