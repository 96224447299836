import request from '@/utils/request'

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUserInfo() {
  return request({
    url: '/user',
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/system/user',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: '/system/user/' + userId,
    method: 'delete'
  })
}

// 导出用户
export function exportUser(query) {
  return request({
    url: '/system/user/export',
    method: 'get',
    params: query
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: '/system/user/importTemplate',
    method: 'get'
  })
}

//修改用户信息
export  function updateUserInfo(data) {
  return request({
    url: '/user',
    method: 'put',
    data: data
  })
}

//获取用户所属机构列表
export function userAgencyMyList() {
  return request({
    url: '/agencyUser/myList',
    method: 'get',
  })
}

//绑定机构——机构搜索
export function userAgencySearch(name) {
  return request({
    url: '/agency/search?name=' + (name ? name : null),
    method: 'get',
  })
}

//绑定机构
export function bindAgencyUser(param) {
  return request({
    url: '/user/bindAgencyUser',
    method: 'post',
    data: param
  })
}

//收藏、取消收藏
export function favorite(param) {
  return request({
    url: '/user/favorite',
    method: 'post',
    data: param
  })
}

//获取用户收藏列表
export function userCollectList(queryParams) {
  return request({
    url: '/user/favoriteList',
    method: 'get',
    params: queryParams
  })
}
//记录用户浏览记录
export function userBrowseRecord(data) {
  return request({
    url: '/user/browse/record',
    method: 'post',
    data: data
  })
}

//获取用户浏览记录列表
export function userBrowseList(data) {
  return request({
    url: '/user/browseList',
    method: 'get'
  })
}

//添加用户中心投诉、建议
export function userOpinion(data) {
  return request({
    url: '/user/opinion',
    method: 'post',
    data: JSON.stringify(data)
  })
}

// 新版3.0.0.1 回收站list
export function trashList(queryParams) {
  return request({
    url: '/trash/list',
    method: 'get',
    params: queryParams
  })
}

// 新版3.0.0.1 回收
export function httpTrash(trashId) {
  return request({
    url: '/trash/recover/' + trashId,
    method: 'post'
  })
}

export const userExtendFieldFormJson = {
  modelName: "formData",
  refName: "vForm",
  rulesName: "rules",
  labelWidth: 67,
  labelPosition: "left",
  size: "small",
  labelAlign: "label-left-align",
  cssCode: "",
  customClass: "",
  functions: "",
  layoutType: "PC",
  jsonVersion: 2,
  dataSources: [],
  onFormCreated: "",
  onFormMounted: "",
  onFormDataChange: "",
  colon: false,
  inputAlign: "left",
  onFormValidate: "",
};